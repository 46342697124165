import styled from 'styled-components';
import variables, { mq } from '../../utils/variables';

export const Card = styled.div`
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  justify-content: space-between;
  box-shadow: -1px 9px 20px rgba(116, 116, 188, 0.25);
  border-radius: 8px;
  margin: 0 20px 30px;

  ${mq('xl')} {
    flex-direction: column;
    padding: 60px 40px;
    width: calc(100% - 40px);
  }
  
  ${mq('xl', true)} {
    flex-direction: ${(props) => (props.highlight) ? 'row-reverse' : 'column' };
    padding: ${(props) => (props.highlight) ? '60px 60px 60px 100px' : '60px 40px' };
    width: ${(props) => (props.highlight) ? 'calc(100% - 40px)' : 'calc(100% / 3 - 40px)' };
  }
  

  .visual {

    ${mq('xl')} {
      width: 100%;
      margin: 0 0 40px 0;
    }

    ${mq('xl', true)} {
      width: ${(props) => (props.highlight) ? '68%' : '100%' };
      margin: ${(props) => (props.highlight) ? '0 0 0 60px' : '0 0 40px 0' };
    }

    .visual-inner {
      width: 100%;
      padding-bottom: 47%;
      position: relative;
    }

    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: cover;

      /* ${mq('xl')} {
        height: 240px;
      }

      ${mq('xl', true)} {
        height: ${(props) => (props.highlight) ? 'auto' : '240px' };
      } */
    }
  }

  .content {
    ${mq('xl')} {
      width: 100%;
      min-width: 100%;
    }

    ${mq('xl', true)} {
      width: ${(props) => (props.highlight) ? '27%' : '100%' };
      min-width: ${(props) => (props.highlight) ? '380px' : '100%' };
    }
  }

  .title {
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.3;
    margin: 0 0 30px;
  }

  .category {
    font-family: 'Bossa', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
    color: ${variables.colors.blue};
    margin: 0 0 15px;
  }

  .excerpt {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 35px;
  }

  .meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .author-avatar {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: 0 10px 0 0;
  }

  .author-name {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }

  .date {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: ${variables.colors.grey};
  }

`;