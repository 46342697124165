import React from 'react';

import { TabsWrapper } from './styled.js';

const TabsFilters = (props) => {
  return (
    <TabsWrapper>
      {props.tabs.map((tab, index) => (
        <li key={index}>
          <a href={tab.url} className={(tab.active ? 'active' : '')}>
            {tab.label}
          </a>
        </li>
      ))}
    </TabsWrapper>
  );
}

export default TabsFilters;