import styled from 'styled-components';
import variables from '../../utils/variables';

export const TabsWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto 50px;
  text-align: center;
  list-style-type: none;

  li {
    + li {
      margin-left: 40px;
    }

    a {
      display: inline-block;
      padding: 0 8px 15px;
      text-decoration: none;
      font-family: 'Bossa', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: ${variables.colors.red};
      border-bottom: 2px solid transparent;

      &:hover {
        color: ${variables.colors.blue};
      }

      &.active {
        border-bottom: 2px solid ${variables.colors.red};
      }
    }
  }
`;