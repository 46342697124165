import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import { Button } from '../components/common/button';
import ContentHero from '../components/content-hero';
import TabsFilters from '../components/tabs-filters';
import ArticleCard from '../components/article-card';

import ArticlePicture from '../images/blog-article-hero.png';
import AuthorAvatar from '../images/blog-article-author-avatar.png';

const ContentSample = () => (
  <Layout
    socialDark={true}
    currentPage='content-sample'
  >
    <SEO title="Content Sample" />

    <Section>
      <ContentHero
        title="All of our thoughts,<br />in one place."
        text="<p>The presentation tool that combines<br />creativity with simplicity.... and a bit of magic.</p>"
      />
    </Section>

    <Section>
      <TabsFilters 
        tabs={[
          {
            label: 'All',
            url: '#',
            active: true
          },
          {
            label: 'Collaboration',
            url: '#'
          },
          {
            label: 'Design',
            url: '#'
          },
          {
            label: 'Content creation',
            url: '#'
          },
          {
            label: 'Pitching ideas',
            url: '#'
          },
        ]}
      />
    </Section>

    <Section>
      <div className="cards-container">
        <ArticleCard 
          highlight={true}
          visual={{
            src: ArticlePicture,
            alt: 'Image alt text'
          }}
          category="Design"
          title="We changed our minds. Bulleted lists are here."
          exerpt="For a very long time, even though it was one of our most requested features, we resisted adding it because it was in opposition to what we believed was a good presentation."
          author={{
            avatar: AuthorAvatar,
            name: 'Vincian Bataglala'
          }}
          date="Nov 21,2020"
        />
        <ArticleCard 
          highlight={false}
          visual={{
            src: ArticlePicture,
            alt: 'Image alt text'
          }}
          category="Design"
          title="We changed our minds. Bulleted lists are here."
          exerpt="For a very long time, even though it was one of our most requested features, we resisted adding it because it was in opposition to what we believed was a good presentation."
          author={{
            avatar: AuthorAvatar,
            name: 'Vincian Bataglala'
          }}
          date="Nov 21,2020"
        />
        <ArticleCard 
          highlight={false}
          visual={{
            src: ArticlePicture,
            alt: 'Image alt text'
          }}
          category="Design"
          title="We changed our minds. Bulleted lists are here."
          exerpt="For a very long time, even though it was one of our most requested features, we resisted adding it because it was in opposition to what we believed was a good presentation."
          author={{
            avatar: AuthorAvatar,
            name: 'Vincian Bataglala'
          }}
          date="Nov 21,2020"
        />
        <ArticleCard 
          highlight={false}
          visual={{
            src: ArticlePicture,
            alt: 'Image alt text'
          }}
          category="Design"
          title="We changed our minds. Bulleted lists are here."
          exerpt="For a very long time, even though it was one of our most requested features, we resisted adding it because it was in opposition to what we believed was a good presentation."
          author={{
            avatar: AuthorAvatar,
            name: 'Vincian Bataglala'
          }}
          date="Nov 21,2020"
        />
        <ArticleCard 
          highlight={false}
          visual={{
            src: ArticlePicture,
            alt: 'Image alt text'
          }}
          category="Design"
          title="We changed our minds. Bulleted lists are here."
          exerpt="For a very long time, even though it was one of our most requested features, we resisted adding it because it was in opposition to what we believed was a good presentation."
          author={{
            avatar: AuthorAvatar,
            name: 'Vincian Bataglala'
          }}
          date="Nov 21,2020"
        />
      </div>

      <div className="button-wrapper">
        <Button className="ghost">
          Load more
        </Button>
      </div>
    </Section>

    
  </Layout>
)

export default ContentSample;
