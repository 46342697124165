import React from 'react';

import { Card } from './styled.js';

const ArticleCard = (props) => {
  return (
    <Card
      highlight={props.highlight}
    >
      {(props.visual) ? (
      <div className="visual">
        <div className="visual-inner">
          <img src={props.visual.src} alt={props.visual.alt} />
        </div>
      </div>
      ) : null}

      <div className="content">
        {(props.category) ? (
          <div className="category">
            {props.category}
          </div>
        ) : null}
        {(props.title) ? (
          <h2 className="title">
            {props.title}
          </h2>
        ) : null}
        {(props.exerpt) ? (
          <div className="excerpt" dangerouslySetInnerHTML={{__html: props.exerpt}} />
        ) : null}
        {(props.author || props.date) ? (
        <div className="meta">
          {(props.author.avatar) ? (
            <img className="author-avatar" src={props.author.avatar} alt={props.author.name} />
          ) : null}
          {(props.author.name || props.date) ? (
            <div className="meta-inner">
              {(props.author.name) ? (
                <span className="author-name">{props.author.name}</span>
              ) : null}
              {(props.date) ? (
                <span className="post-date">{props.date}</span>
              ) : null}
            </div>
          ) : null}
        </div>
        ) : null}
      </div>
    </Card>
  );
};

export default ArticleCard;